"use client";

import { EventEmitter } from "events";
import { useEffect, useRef, useState } from "react";

const WebSocketUrl = `${process.env.NEXT_PUBLIC_WS_URL}/v2/websocket`;

const ERROR_HEADER = "소켓 연결 오류: ";

const socketConsoleError = (msg: string) => console.error(ERROR_HEADER + msg);

const safeJsonParse = (data: unknown): Record<string, any> => {
  try {
    return JSON.parse(data as string) as Record<string, any>;
  } catch (e) {
    socketConsoleError(`Failed to parse JSON, ${JSON.stringify(e)}`);
    return {};
  }
};

const DEFAULT_RECONNECT_COUNT = 5;

export const eventEmitter = new EventEmitter();

export const useLabspaceWebSocket = (): void => {
  const webSocketRef = useRef<WebSocket | null>(null);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const reconnectTimerRef = useRef<NodeJS.Timeout | null>(null);
  const isConnectedRef = useRef(false);

  const cleanupWebSocket = () => {
    if (reconnectTimerRef.current) {
      clearTimeout(reconnectTimerRef.current);
      reconnectTimerRef.current = null;
    }
    if (webSocketRef.current) {
      webSocketRef.current.close();
      webSocketRef.current = null;
    }
  };

  const handleOpen = () => {
    isConnectedRef.current = true;
    setReconnectAttempts(0);
    if (reconnectTimerRef.current) {
      clearTimeout(reconnectTimerRef.current);
      reconnectTimerRef.current = null;
    }
  };

  const handleMessage = (e: MessageEvent) => {
    try {
      const data = safeJsonParse(e.data);

      const isCalcSocket = data?.event === "requested_calculation";
      if (isCalcSocket) {
        eventEmitter.emit("update_calc_query", data);
        return;
      }

      const isPushSocket = "metadata" in data;
      if (isPushSocket) {
        eventEmitter.emit("update_query", data?.metadata);
        eventEmitter.emit("push", data);
      } else {
        eventEmitter.emit("update_query", data);
      }

      const isClientSocket = ["hyper_screening", "hyper_design"].includes(
        data?.type as string,
      );
      if (isClientSocket) {
        eventEmitter.emit(data?.type, data);
      }

      const isSharedBenchSocket =
        data.type === "project_bench_changed" ||
        ["deactivate_protein_structure", "activate_protein_structure"].includes(
          data?.metadata?.type,
        );
      if (isSharedBenchSocket) {
        eventEmitter.emit("project_bench_stale", data);
      }
    } catch (error) {
      socketConsoleError(`handleMessage: catch ${JSON.stringify(error)}`);
    }
  };

  const retryConnection = () => {
    webSocketRef.current = null;
    isConnectedRef.current = false;

    if (!reconnectTimerRef.current) {
      reconnectTimerRef.current = setTimeout(
        () => {
          setReconnectAttempts((prev) => prev + 1);
          reconnectTimerRef.current = null;
        },
        // 2, 4, 8, 16, 최대 30초까지 연결대기시간을 늘림
        Math.min(1000 * 2 ** reconnectAttempts, 30000),
      );

      if (reconnectAttempts >= DEFAULT_RECONNECT_COUNT) {
        socketConsoleError("최대 연결요청에 도달 - 소켓서버 확인필요");
        reconnectTimerRef.current = setTimeout(() => {
          setReconnectAttempts(DEFAULT_RECONNECT_COUNT);
          reconnectTimerRef.current = null;
        }, 30000);
      }
    }
  };
  const handleError = (e: Event) => {
    retryConnection();
  };

  const handleClose = (e: CloseEvent) => {
    socketConsoleError(`WebSocket closed ${e.code} ${e.reason}`);
    retryConnection();
  };

  const connectWebSocket = () => {
    const socket = new WebSocket(WebSocketUrl);

    webSocketRef.current = socket;

    webSocketRef.current.onopen = handleOpen;
    webSocketRef.current.onmessage = handleMessage;
    webSocketRef.current.onerror = handleError;
    webSocketRef.current.onclose = handleClose;
  };

  useEffect(() => {
    if (webSocketRef.current === null && isConnectedRef.current === false) {
      connectWebSocket();
    }

    return () => {
      const SOCKET_IS_CONNECTING = webSocketRef.current?.readyState === 0;
      const SOCKET_IS_OPEN = webSocketRef.current?.readyState === 1;
      const SOCKET_IS_CLOSING = webSocketRef.current?.readyState === 2;
      const SOCKET_IS_CLOSED = webSocketRef.current?.readyState === 3;

      if (SOCKET_IS_CONNECTING || SOCKET_IS_OPEN) {
        return;
      }

      if (SOCKET_IS_CLOSING || SOCKET_IS_CLOSED) {
        return cleanupWebSocket();
      }
    };
  }, [reconnectAttempts]);

  useEffect(() => {
    return () => {
      if (webSocketRef.current?.readyState === WebSocket.OPEN) {
        webSocketRef.current?.close();
      }
    };
  }, []);
};
